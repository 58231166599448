@charset "utf-8";

@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}


::selection{background:#000;color:#fff;}
::-moz-selection{background:#000;color:#fff;}

*{
	box-sizing: border-box;
}
html{
	position: relative;
	overflow-x: hidden;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	font-family: $fontBase;
	line-height: $lineHeight;
	letter-spacing: .1em;
	color: $textColor;
	background-color: #fff;
	position: relative;
	overflow-x: hidden;
	// -webkit-text-stroke-width: 0.1px;
}

img,
svg{
	width: 100%;
	height: auto;
}
.inner{
	position: relative;
	z-index: 1;
	width: 90%;
	max-width: 1290px;
	margin: auto;
	// padding: 0 15px;
	// box-sizing:	content-box;
}
.inside{
	position: relative;
	// width: 90%;
	max-width: 1130px;
	margin: auto;
}
.in{
	position: relative;
	// width: 90%;
	margin: auto;
}



.pc{display: none;}
.smt{display: block;}

ul{
	letter-spacing: -.4em;
	li{
		letter-spacing: normal;
		.li-in{
			letter-spacing: .1em;
		}
	}
}

// heading
.h-large,
.h-middle,
.h-small{
	color: #000;
	font-family: $fontJa;
	font-weight: 600;
}

.h-middle{
	font-size: 16px;
}

.h-draw,
.h-draw-white{
	span{
		display: block;
		line-height: 0;
		padding: 5px 0;
		svg{
			width: auto;
			height: 20px;
		}
	}
	small{
		display: block;
		color: #000;
		font-size: 14px;
		font-family: $fontJa;
		font-weight: 600;
	}
}
.h-draw-white{
	small{
		color: #fff;
	}
}



.lazy{
	background-image: url(../img/common/ajax-loader.gif);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 24px auto;
}

a.permalink{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
}

h1{
	display: none !important;
}

.f_robot{
	font-family: $fontRobot;
	font-weight: 300;
}

#load-wrap{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background-color: #fff;
}

#loader{
	position: absolute;
	top: 50%;
	left: 50%;
	color: #9d9d9d;
	font-size: 16px;
	letter-spacing: 3px;
	transform: translate(-50%, -50%);
}



header{
	
}

#hgroup{
	.hgroup-in{
		background-color: #fff;
	}
}

#logo{
	width: 70%;
	// margin: auto;
	a{
		// text-align: center;
		display: block;
		line-height: 0;
		padding: 20px 10px;
		img{
			max-width: 240px;
		}
	}
}

nav#global{
	display: none;
}

.btns{
	text-align: center;
	margin: 5% 0 0;
	a,
	button{
		cursor: pointer;
		text-align: center;
		display: inline-block;
		width: 100%;
		max-width: 300px;
		color: #fff;
		font-size: 15px;
		font-family: $fontBase;
		padding: 15px;
		color: #fff;
		background-color: $baseColor;
		box-shadow: 5px 5px 0 rgba(0,0,0,0.2);
		transition: all .2s;
	}
	a:hover,
	button:hover{
		box-shadow: 0 0 0 rgba(0,0,0,0.2);
	}
	a.b-small,
	button.b-small{
		padding: 10px;
		max-width: 260px;
		box-shadow: 0 0 0 rgba(0,0,0,0);
	}
	.col2{
		letter-spacing: -.4em;
		margin: 0 -2%;
		a,
		button{
			letter-spacing: normal;
			width: 46%;
			max-width: 240px;
			margin: 0 2%;
		}
	}
	a.darken,
	button.darken{
		background-color: darken($baseColor, 10%);
	}
	a.gray,
	button.gray{
		background-color:#aaa;
	}
	a.orange,
	button.orange{
		background-color:#E15F00;
	}
	a.teal,
	button.teal{
		background-color:$colorRent;
	}
	a.green,
	button.green{
		background-color:$colorSale;
	}

}


.guides{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	> .in {
		max-width: 1290px;
		width: 100%;
		margin: auto;
		height: 100%;
		display: table;
	}
	.line {
		width: 12.5%;
		display: table-cell;
		border-right: 1px solid #E6E6E6;
		box-sizing: border-box;
	}
	.line:first-child {
		border-left: 1px solid #E6E6E6;
	}
	.line:nth-child(3) {
		border-right: none;
	}
	.line:nth-child(4),
	.line:nth-child(5),
	.line:nth-child(6),
	.line:nth-child(7),
	.line:nth-child(8) {
		display: none;
	}
}


footer{
	position: relative;
	text-align: center;
	// background-color: #fff;
	background: url(../img/common/footer/bg.jpg) no-repeat center bottom;
	background-size: cover;
	margin: 12% 0 0;
	.inner{
		padding: 12% 15px;
		max-width: 1200px;
	}
	.foot-left{
		.bnrs{
			margin: 30px 0 0;
			ul{
				li{
					line-height: 0;
					margin: 15px 0 0;
					a{
						display: inline-block;
						width: 40%;
						max-width: 180px;
						margin: auto;
					}
				}
				li:first-of-type{
					margin: 0;
				}
			}
		}
	}
	.foot-right{
		display: none;
	}
}

#f-logo{
	display: inline-block;
	width: 70%;
	max-width: 330px;
	margin: 0 0 10px;
}
#copyright{
	text-align: center;
	// background-color: #fff;
	padding: 10px;
	color: #333;
	font-size: 12px;
}


#menu-btn {
	cursor: pointer;
	display: inline-block;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 8;
	width: 60px;
	height: 60px;
	background-color: #fff;
	> div{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 30px;
		height: 24px;
		transform: translate(-50%, -50%);
	}
	span {
		display: inline-block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: #000;
	}
	span:nth-of-type(1) {
		top: 0;
	}
	span:nth-of-type(2) {
		top: 11px;
	}
	span:nth-of-type(3) {
		bottom: 0;
	}
}
#nav-smt{
	overflow-y: scroll;
	position: fixed;
	top: 0;
	left: 100%;
	z-index: 10;
	width: 100%;
	height: 100%;
	padding: 15px 15px 0;
	box-sizing: border-box;
	transition: all .5s;
	.nav-in{
		position: relative;
		background-color: #fff;
		padding: 20px;
		margin: 0 0 15px;
	}
	ul.super{
		> li{
			> a,
			> span{
				position: relative;
				letter-spacing: .1em;
				display: block;
				padding: 10px 5px 10px 20px;
				border-top: 1px dotted #ccc;
				b,
				small{
					display: inline-block;
					vertical-align: middle;
				}
				b{
					color: $baseColor;
					font-size: 15px;
					font-weight: bold;
				}
				small{
					color: #9d9d9d;
					font-family: $fontRobot;
					margin-left: 15px;
				}
			}
			> a:before{
				content: '';
				display: block;
				position: absolute;
				top: 20px;
				left: 5px;
				border: 4px solid transparent;
				border-left: 5px solid $baseColor;
			}
			> span:before{
				content: '';
				display: block;
				position: absolute;
				top: 22px;
				left: 3px;
				border: 4px solid transparent;
				border-top: 5px solid $baseColor;
			}
		}
		> li:first-of-type{
			> a,
			> span{
				border: 0;
			}
		}
	}
	ul.child{
		margin: 0 0 10px;
		li{
			a{
				position: relative;
				display: block;
				padding: 5px 10px 5px 20px;
				small{
					display: block;
					vertical-align: middle;
					color: #9d9d9d;
					font-size: 12px;
				}

			}
			a:before{
				content: '';
				display: block;
				position: absolute;
				top: 15px;
				left: 3px;
				width: 5px;
				height: 5px;
				border-top: 1px solid #9d9d9d;
				border-right: 1px solid #9d9d9d;
				transform: rotate(45deg);
			}
		}
	}
	.nav-foot{
		padding: 30px 0 25px;
		text-align: center;
		.tel{
			color: $baseColor;
			font-size: 24px;
			font-family: $fontRobot;
			line-height: 1.5;
			font-weight: 700;
			margin: 10px 0 0;
		}
	}
}
#nav-smt.show{
	left: 0;
}

a#nav-logo{
	display: block;
	line-height: 0;
	width: 80%;
	max-width: 330px;
	margin: 40px auto;
}

#nav-close,
#nav-close span {
	cursor: pointer;
	display: inline-block;
	box-sizing: border-box;
}
#nav-close {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 30px;
	height: 24px;
	z-index: 8;
	span {
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: #000;
	}
	span:nth-of-type(1) {
		top: 0;
		transform: translateY(11px) rotate(-315deg);
	}
	span:nth-of-type(2) {
		bottom: 0;
		transform: translateY(-11px) rotate(315deg);
	}
}

#overlay{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
}

#pagetop{
	display: none;
	cursor: pointer;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 8;
	background-color: $baseColor;
	width: 60px;
	height: 60px;
	span{
		display: block;
		position: absolute;
		top: 55%;
		left: 50%;
		width: 15px;
		height: 15px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

#pageback{
	display: none;
	cursor: pointer;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 8;
	background-color: $baseColor;
	width: 60px;
	height: 60px;
	a{
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		b{
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 15px;
			height: 15px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
			transform: translate(-50%, -50%) rotate(-135deg);
		}
	}
}


// ==================== lower 
#lower{
	header{
		position: relative;
		box-shadow: 0 0 13px -4px;
	}
	#logo{
		margin: 0;
	}

	#main-view{
		position: relative;
		.main-bg{
			span{
				display: block;
				line-height: 0;
				position: relative;
			}
			span:before{
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				// background: url(../img/common/dot.png);
				background-color: rgba(0,0,0,0.2);
			}
		}
		h2{
			text-align: center;
			position: absolute;
			top: 60%;
			left: 0;
			z-index: 2;
			width: 100%;
			height: auto;
			color: #fff;
			letter-spacing: .3em;
			transform: translateY(-50%);
			opacity: 0;
			transition: all 1s 1.2s;
			strong{
				display: block;
				font-size: 18px;
				font-family: $fontJa;
				font-weight: 600;
				i{
					font-size: 1.2em;
					margin: 0 5px 0 0;
				}
			}
			small{
				font-family: $fontRobot;
			}
		}
	}
	#main-view.on{
		h2{
			top: 50%;
			opacity: 1;
		}
	}

	.sec{
		padding: 10% 0 0;
		.sec-head{
			text-align: center;
			margin: 0 0 8%;
			h3{
				font-size: 16px;
				font-family: $fontJa;
				font-weight: 500;
				margin: 0 0 10px;
				span{
					display: inline-block;
				}
			}
			p{
				font-size: 14px;
			}
		}
		.sec-body{
			p{
				margin: 10px 0 0;
			}
		}
	}

}

#breadcrumb {
	display: none;
}

.noentry{
	letter-spacing: normal;
}


@media screen and (min-width: 600px) {

	.h-middle{
		font-size: 18px;
	}
	.h-draw,
	.h-draw-white{
		span{
			svg{
				height: 25px;
			}
		}
	}

	.btns{
		a{
			padding: 15px;
		}
	}

	footer{
		margin: 10% 0 0;
		.inner{
			padding: 10% 15px;
		}
	}

// ==================== lower 
	#lower{
		#main-view{
			h2{
				strong{
					font-size: 22px;
				}
			}
		}

		.sec{
			padding: 8% 0 0;
			.sec-head{
				margin: 0 0 6%;
				h3{
					font-size: 18px;
				}
			}
		}
	}

}


@media screen and (min-width: 800px) {
	.pc{display: block;}
	.smt{display: none;}

	.inner{
		width: 100%;
	}
	.inside,
	.in{
		width: 90%;
	}
	.in{
		max-width: 75%;
	}

	.guides{
		.line:nth-child(3) {
			border-right: 1px solid #E6E6E6;
		}
		.line:nth-child(4),
		.line:nth-child(5),
		.line:nth-child(6),
		.line:nth-child(7),
		.line:nth-child(8) {
			display: table-cell;
		}
	}

	.h-large,
	.h-middle,
	.h-small{
		span{
			display: inline-block;
		}
	}
	.h-middle{
		font-size: 20px;
	}
	.h-draw,
	.h-draw-white{
		span{
			padding: 10px 0;
			svg{
				height: 30px;
			}
		}
		small{
			font-size: 16px;
		}
	}
	
	#logo{
		display: inline-block;
		vertical-align: middle;
		width: 25%;
		a{
			text-align: left;
			img{
				max-width: 280px;
			}
		}
	}
	nav#global{
		text-align: right;
		display: inline-block;
		vertical-align: middle;
		width: 75%;
		line-height: 1.8;
		ul.super{
			> li{
				position: relative;
				display: inline-block;
				> a,
				> span{
					text-align: center;
					letter-spacing: .1em;
					display: block;
					padding: 15px 20px;
					font-size: 12px;
					small{
						display: block;
						color: #9d9d9d;
						font-size: 11px;
					}
				}
				> a:hover,
				> span.hover{
					color: $baseColor;
				}
			}
			> li:before{
				content: '';
				display: block;
				width: 1px;
				height: 30px;
				position: absolute;
				top: 50%;
				left: 0;
				background-color: rgba(0,0,0,0.2);
				transform: translateY(-50%);
			}
			> li:first-of-type:before{
				display: none;
			}
		}
		ul.child{
			display: none;
			white-space: nowrap;
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 3;
			min-width: 100%;
			background-color: #fff;
			box-shadow: 3px 3px 0 rgba(0,0,0,0.3);
			li{
				a{
					letter-spacing: .1em;
					text-align: left;
					position: relative;
					display: block;
					padding: 10px 30px 10px 35px;
					background-color: #eee;
					border-top: 1px dashed #ccc;
					small{
						display: block;
						vertical-align: middle;
						color: #9d9d9d;
						font-size: 12px;
					}
				}
				a:before{
					content: '';
					display: block;
					position: absolute;
					top: 18px;
					left: 15px;
					width: 5px;
					height: 5px;
					border-top: 1px solid #9d9d9d;
					border-right: 1px solid #9d9d9d;
					transform: rotate(45deg);
				}
				a:hover{
					color: $baseColor;
					background-color: #ddd;
				}
			}
			li:first-of-type{
				a{
					border: 0;
				}
				a:after{
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 20px;
					border: 7px solid transparent;
					border-right: 8px solid #eee;
					border-bottom: 8px solid #eee;
					transform: translateY(-100%); 
				}
			}
		}
	}

	footer{
		text-align: left;
		margin: 80px 0 0;
		.inner{
			padding: 80px 15px 40px;
		}
		.foot-left,
		.foot-right{
			display: inline-block;
			vertical-align: top;
		}
		.foot-left{
			width: 40%;	
		}
		.foot-right{
			width: 60%;
			.f-links{
				overflow: hidden;
				ul.super{
					float: right;
					width: 100%;
					// max-width: 600px;
					padding-left: 10%;
					border-left: 1px solid #ccc;
					li{
						a,
						span{
							position: relative;
							letter-spacing: .1em;
							display: block;
							padding: 3px 0 3px 1em;
						}
						a:hover{
							color: $baseColor;
						}
					}
					> li{
						display: inline-block;
						vertical-align: top;
						width: 40%;
						> a:before{
							content: '';
							display: block;
							position: absolute;
							top: 11px;
							left: 0;
							width: 0;
							height: 0;
							border: 4px solid transparent;
							border-left: 5px solid #9d9d9d;
						}
						> a:hover:before{
							border-left: 5px solid $baseColor;
						}
						span:before{
							content: '';
							display: block;
							position: absolute;
							top: 14px;
							left: -3px;
							width: 0;
							height: 0;
							border: 4px solid transparent;
							border-top: 5px solid #9d9d9d;
						}
					}
					> li:last-of-type{
						width: 20%;
					}
				}
				ul.child{
					li{
						a{
							margin-left: 1em;
							small{
								display: block;
								vertical-align: middle;
								color: #9d9d9d;
								font-size: 12px;
							}
						}
						a:before{
							content: '';
							display: block;
							position: absolute;
							top: 12px;
							left: -2px;
							width: 5px;
							height: 5px;
							border-top: 1px solid #9d9d9d;
							border-right: 1px solid #9d9d9d;
							transform: rotate(45deg);
						}
						a:hover:before{
							border-top: 1px solid $baseColor;
							border-right: 1px solid $baseColor;
						}
					}
				}
			}
			
		}
	}

	#menu-btn {
		display: none;
	}

	#pagetop,
	#pageback{
		width: 80px;
		height: 80px;
	}


// ==================== lower 
	#lower{
		#main-view{
			h2{
				strong{
					font-size: 26px;
				}
			}
		}
		.sec{
			padding: 6% 0 0;
			.sec-head{
				text-align: left;
				margin: 0 0 4%;
				h3{
					font-size: 20px;
				}
			}
		}
	}
	#breadcrumb {
		display: block;
		margin: 0 auto;
		box-sizing: content-box;
		.inner{
			padding: 15px;
			ol {
				overflow: hidden;
				li {
					float: left;
					font-size: 13px;
					margin: 0px 10px 0px 0px;
					a {
						background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 5px;
						padding: 0px 14px 0px 0px;
					}
					a:hover{
						text-decoration: underline;
					}
				}
				li.categorize {
					background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 8px;
					padding: 0px 14px 0px 0px;
				}
			}
		}
	}

}


@media screen and (min-width: 1000px) {
	.h-draw,
	.h-draw-white{
		span{
			svg{
				height: 35px;
			}
		}
	}
	
	nav#global{
		display: inline-block;
		line-height: 1.8;
		ul.super{
			> li{
				display: inline-block;
				> a,
				> span{
					text-align: center;
					letter-spacing: .1em;
					display: block;
					padding: 15px 25px;
					font-size: 13px;
					small{
						display: block;
						color: #ccc;
						font-size: 11px;
					}
				}
			}
		}
	}

	footer{
		margin: 100px 0 0;
		.inner{
			padding: 100px 15px 50px;
		}
	}


// ==================== lower 
	#lower{
		#main-view{
			h2{
				strong{
					font-size: 30px;
				}
			}
		}
		.sec{
			.sec-head{
				margin: 0 0 40px;
				h3{
					font-size: 22px;
				}
			}
		}
	}

}


@media screen and (min-width: 1200px) {
	nav#global{
		ul.super{
			> li{
				> a,
				> span{
					padding: 15px 30px;
					font-size: 14px;
				}
			}
		}
	}

// ==================== lower 
	#lower{
		#main-view{
			.main-bg{
				span{
					overflow: hidden;
					height: 380px;
					img{
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						height: auto;
						transform: translate(-50%, -50%);
					}
				}
			}
			h2{
				strong{
					font-size: 34px;
				}
			}
		}
		.sec{
			padding: 70px 0 0;
			h3{
				font-size: 24px;
			}
		}
	}

}
