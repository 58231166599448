@charset "UTF-8";
/* colors */
/* font-family */
/* easing */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, input, textarea, button, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: inherit;
  font-style: normal;
  vertical-align: baseline;
}

body {
  -webkit-text-size-adjust: 100%;
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

img {
  -ms-interpolation-mode: bicubic;
}

::selection {
  background: #000;
  color: #fff;
}

::-moz-selection {
  background: #000;
  color: #fff;
}

* {
  box-sizing: border-box;
}

html {
  position: relative;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  line-height: 2;
  letter-spacing: .1em;
  color: #333;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
}

img,
svg {
  width: 100%;
  height: auto;
}

.inner {
  position: relative;
  z-index: 1;
  width: 90%;
  max-width: 1290px;
  margin: auto;
}

.inside {
  position: relative;
  max-width: 1130px;
  margin: auto;
}

.in {
  position: relative;
  margin: auto;
}

.pc {
  display: none;
}

.smt {
  display: block;
}

ul {
  letter-spacing: -.4em;
}

ul li {
  letter-spacing: normal;
}

ul li .li-in {
  letter-spacing: .1em;
}

.h-large,
.h-middle,
.h-small {
  color: #000;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
}

.h-middle {
  font-size: 16px;
}

.h-draw span,
.h-draw-white span {
  display: block;
  line-height: 0;
  padding: 5px 0;
}

.h-draw span svg,
.h-draw-white span svg {
  width: auto;
  height: 20px;
}

.h-draw small,
.h-draw-white small {
  display: block;
  color: #000;
  font-size: 14px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
}

.h-draw-white small {
  color: #fff;
}

.lazy {
  background-image: url(../img/common/ajax-loader.gif);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 24px auto;
}

a.permalink {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

h1 {
  display: none !important;
}

.f_robot {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

#load-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #9d9d9d;
  font-size: 16px;
  letter-spacing: 3px;
  transform: translate(-50%, -50%);
}

#hgroup .hgroup-in {
  background-color: #fff;
}

#logo {
  width: 70%;
}

#logo a {
  display: block;
  line-height: 0;
  padding: 20px 10px;
}

#logo a img {
  max-width: 240px;
}

nav#global {
  display: none;
}

.btns {
  text-align: center;
  margin: 5% 0 0;
}

.btns a,
.btns button {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  color: #fff;
  font-size: 15px;
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  padding: 15px;
  color: #fff;
  background-color: #1469aa;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  transition: all .2s;
}

.btns a:hover,
.btns button:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.btns a.b-small,
.btns button.b-small {
  padding: 10px;
  max-width: 260px;
  box-shadow: 0 0 0 transparent;
}

.btns .col2 {
  letter-spacing: -.4em;
  margin: 0 -2%;
}

.btns .col2 a,
.btns .col2 button {
  letter-spacing: normal;
  width: 46%;
  max-width: 240px;
  margin: 0 2%;
}

.btns a.darken,
.btns button.darken {
  background-color: #0f4d7c;
}

.btns a.gray,
.btns button.gray {
  background-color: #aaa;
}

.btns a.orange,
.btns button.orange {
  background-color: #E15F00;
}

.btns a.teal,
.btns button.teal {
  background-color: #009788;
}

.btns a.green,
.btns button.green {
  background-color: #008446;
}

.guides {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.guides > .in {
  max-width: 1290px;
  width: 100%;
  margin: auto;
  height: 100%;
  display: table;
}

.guides .line {
  width: 12.5%;
  display: table-cell;
  border-right: 1px solid #E6E6E6;
  box-sizing: border-box;
}

.guides .line:first-child {
  border-left: 1px solid #E6E6E6;
}

.guides .line:nth-child(3) {
  border-right: none;
}

.guides .line:nth-child(4),
.guides .line:nth-child(5),
.guides .line:nth-child(6),
.guides .line:nth-child(7),
.guides .line:nth-child(8) {
  display: none;
}

footer {
  position: relative;
  text-align: center;
  background: url(../img/common/footer/bg.jpg) no-repeat center bottom;
  background-size: cover;
  margin: 12% 0 0;
}

footer .inner {
  padding: 12% 15px;
  max-width: 1200px;
}

footer .foot-left .bnrs {
  margin: 30px 0 0;
}

footer .foot-left .bnrs ul li {
  line-height: 0;
  margin: 15px 0 0;
}

footer .foot-left .bnrs ul li a {
  display: inline-block;
  width: 40%;
  max-width: 180px;
  margin: auto;
}

footer .foot-left .bnrs ul li:first-of-type {
  margin: 0;
}

footer .foot-right {
  display: none;
}

#f-logo {
  display: inline-block;
  width: 70%;
  max-width: 330px;
  margin: 0 0 10px;
}

#copyright {
  text-align: center;
  padding: 10px;
  color: #333;
  font-size: 12px;
}

#menu-btn {
  cursor: pointer;
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 8;
  width: 60px;
  height: 60px;
  background-color: #fff;
}

#menu-btn > div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 24px;
  transform: translate(-50%, -50%);
}

#menu-btn span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

#menu-btn span:nth-of-type(1) {
  top: 0;
}

#menu-btn span:nth-of-type(2) {
  top: 11px;
}

#menu-btn span:nth-of-type(3) {
  bottom: 0;
}

#nav-smt {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: 15px 15px 0;
  box-sizing: border-box;
  transition: all .5s;
}

#nav-smt .nav-in {
  position: relative;
  background-color: #fff;
  padding: 20px;
  margin: 0 0 15px;
}

#nav-smt ul.super > li > a,
#nav-smt ul.super > li > span {
  position: relative;
  letter-spacing: .1em;
  display: block;
  padding: 10px 5px 10px 20px;
  border-top: 1px dotted #ccc;
}

#nav-smt ul.super > li > a b,
#nav-smt ul.super > li > a small,
#nav-smt ul.super > li > span b,
#nav-smt ul.super > li > span small {
  display: inline-block;
  vertical-align: middle;
}

#nav-smt ul.super > li > a b,
#nav-smt ul.super > li > span b {
  color: #1469aa;
  font-size: 15px;
  font-weight: bold;
}

#nav-smt ul.super > li > a small,
#nav-smt ul.super > li > span small {
  color: #9d9d9d;
  font-family: "Roboto", sans-serif;
  margin-left: 15px;
}

#nav-smt ul.super > li > a:before {
  content: '';
  display: block;
  position: absolute;
  top: 20px;
  left: 5px;
  border: 4px solid transparent;
  border-left: 5px solid #1469aa;
}

#nav-smt ul.super > li > span:before {
  content: '';
  display: block;
  position: absolute;
  top: 22px;
  left: 3px;
  border: 4px solid transparent;
  border-top: 5px solid #1469aa;
}

#nav-smt ul.super > li:first-of-type > a,
#nav-smt ul.super > li:first-of-type > span {
  border: 0;
}

#nav-smt ul.child {
  margin: 0 0 10px;
}

#nav-smt ul.child li a {
  position: relative;
  display: block;
  padding: 5px 10px 5px 20px;
}

#nav-smt ul.child li a small {
  display: block;
  vertical-align: middle;
  color: #9d9d9d;
  font-size: 12px;
}

#nav-smt ul.child li a:before {
  content: '';
  display: block;
  position: absolute;
  top: 15px;
  left: 3px;
  width: 5px;
  height: 5px;
  border-top: 1px solid #9d9d9d;
  border-right: 1px solid #9d9d9d;
  transform: rotate(45deg);
}

#nav-smt .nav-foot {
  padding: 30px 0 25px;
  text-align: center;
}

#nav-smt .nav-foot .tel {
  color: #1469aa;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  font-weight: 700;
  margin: 10px 0 0;
}

#nav-smt.show {
  left: 0;
}

a#nav-logo {
  display: block;
  line-height: 0;
  width: 80%;
  max-width: 330px;
  margin: 40px auto;
}

#nav-close,
#nav-close span {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
}

#nav-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 24px;
  z-index: 8;
}

#nav-close span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

#nav-close span:nth-of-type(1) {
  top: 0;
  transform: translateY(11px) rotate(-315deg);
}

#nav-close span:nth-of-type(2) {
  bottom: 0;
  transform: translateY(-11px) rotate(315deg);
}

#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

#pagetop {
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 8;
  background-color: #1469aa;
  width: 60px;
  height: 60px;
}

#pagetop span {
  display: block;
  position: absolute;
  top: 55%;
  left: 50%;
  width: 15px;
  height: 15px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translate(-50%, -50%) rotate(-45deg);
}

#pageback {
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  background-color: #1469aa;
  width: 60px;
  height: 60px;
}

#pageback a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

#pageback a b {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translate(-50%, -50%) rotate(-135deg);
}

#lower header {
  position: relative;
  box-shadow: 0 0 13px -4px;
}

#lower #logo {
  margin: 0;
}

#lower #main-view {
  position: relative;
}

#lower #main-view .main-bg span {
  display: block;
  line-height: 0;
  position: relative;
}

#lower #main-view .main-bg span:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

#lower #main-view h2 {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 0;
  z-index: 2;
  width: 100%;
  height: auto;
  color: #fff;
  letter-spacing: .3em;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 1s 1.2s;
}

#lower #main-view h2 strong {
  display: block;
  font-size: 18px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
}

#lower #main-view h2 strong i {
  font-size: 1.2em;
  margin: 0 5px 0 0;
}

#lower #main-view h2 small {
  font-family: "Roboto", sans-serif;
}

#lower #main-view.on h2 {
  top: 50%;
  opacity: 1;
}

#lower .sec {
  padding: 10% 0 0;
}

#lower .sec .sec-head {
  text-align: center;
  margin: 0 0 8%;
}

#lower .sec .sec-head h3 {
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 500;
  margin: 0 0 10px;
}

#lower .sec .sec-head h3 span {
  display: inline-block;
}

#lower .sec .sec-head p {
  font-size: 14px;
}

#lower .sec .sec-body p {
  margin: 10px 0 0;
}

#breadcrumb {
  display: none;
}

.noentry {
  letter-spacing: normal;
}

@media screen and (min-width: 600px) {
  .h-middle {
    font-size: 18px;
  }
  .h-draw span svg,
  .h-draw-white span svg {
    height: 25px;
  }
  .btns a {
    padding: 15px;
  }
  footer {
    margin: 10% 0 0;
  }
  footer .inner {
    padding: 10% 15px;
  }
  #lower #main-view h2 strong {
    font-size: 22px;
  }
  #lower .sec {
    padding: 8% 0 0;
  }
  #lower .sec .sec-head {
    margin: 0 0 6%;
  }
  #lower .sec .sec-head h3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 800px) {
  .pc {
    display: block;
  }
  .smt {
    display: none;
  }
  .inner {
    width: 100%;
  }
  .inside,
  .in {
    width: 90%;
  }
  .in {
    max-width: 75%;
  }
  .guides .line:nth-child(3) {
    border-right: 1px solid #E6E6E6;
  }
  .guides .line:nth-child(4),
  .guides .line:nth-child(5),
  .guides .line:nth-child(6),
  .guides .line:nth-child(7),
  .guides .line:nth-child(8) {
    display: table-cell;
  }
  .h-large span,
  .h-middle span,
  .h-small span {
    display: inline-block;
  }
  .h-middle {
    font-size: 20px;
  }
  .h-draw span,
  .h-draw-white span {
    padding: 10px 0;
  }
  .h-draw span svg,
  .h-draw-white span svg {
    height: 30px;
  }
  .h-draw small,
  .h-draw-white small {
    font-size: 16px;
  }
  #logo {
    display: inline-block;
    vertical-align: middle;
    width: 25%;
  }
  #logo a {
    text-align: left;
  }
  #logo a img {
    max-width: 280px;
  }
  nav#global {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    width: 75%;
    line-height: 1.8;
  }
  nav#global ul.super > li {
    position: relative;
    display: inline-block;
  }
  nav#global ul.super > li > a,
  nav#global ul.super > li > span {
    text-align: center;
    letter-spacing: .1em;
    display: block;
    padding: 15px 20px;
    font-size: 12px;
  }
  nav#global ul.super > li > a small,
  nav#global ul.super > li > span small {
    display: block;
    color: #9d9d9d;
    font-size: 11px;
  }
  nav#global ul.super > li > a:hover,
  nav#global ul.super > li > span.hover {
    color: #1469aa;
  }
  nav#global ul.super > li:before {
    content: '';
    display: block;
    width: 1px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    transform: translateY(-50%);
  }
  nav#global ul.super > li:first-of-type:before {
    display: none;
  }
  nav#global ul.child {
    display: none;
    white-space: nowrap;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3;
    min-width: 100%;
    background-color: #fff;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  }
  nav#global ul.child li a {
    letter-spacing: .1em;
    text-align: left;
    position: relative;
    display: block;
    padding: 10px 30px 10px 35px;
    background-color: #eee;
    border-top: 1px dashed #ccc;
  }
  nav#global ul.child li a small {
    display: block;
    vertical-align: middle;
    color: #9d9d9d;
    font-size: 12px;
  }
  nav#global ul.child li a:before {
    content: '';
    display: block;
    position: absolute;
    top: 18px;
    left: 15px;
    width: 5px;
    height: 5px;
    border-top: 1px solid #9d9d9d;
    border-right: 1px solid #9d9d9d;
    transform: rotate(45deg);
  }
  nav#global ul.child li a:hover {
    color: #1469aa;
    background-color: #ddd;
  }
  nav#global ul.child li:first-of-type a {
    border: 0;
  }
  nav#global ul.child li:first-of-type a:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    border: 7px solid transparent;
    border-right: 8px solid #eee;
    border-bottom: 8px solid #eee;
    transform: translateY(-100%);
  }
  footer {
    text-align: left;
    margin: 80px 0 0;
  }
  footer .inner {
    padding: 80px 15px 40px;
  }
  footer .foot-left,
  footer .foot-right {
    display: inline-block;
    vertical-align: top;
  }
  footer .foot-left {
    width: 40%;
  }
  footer .foot-right {
    width: 60%;
  }
  footer .foot-right .f-links {
    overflow: hidden;
  }
  footer .foot-right .f-links ul.super {
    float: right;
    width: 100%;
    padding-left: 10%;
    border-left: 1px solid #ccc;
  }
  footer .foot-right .f-links ul.super li a,
  footer .foot-right .f-links ul.super li span {
    position: relative;
    letter-spacing: .1em;
    display: block;
    padding: 3px 0 3px 1em;
  }
  footer .foot-right .f-links ul.super li a:hover {
    color: #1469aa;
  }
  footer .foot-right .f-links ul.super > li {
    display: inline-block;
    vertical-align: top;
    width: 40%;
  }
  footer .foot-right .f-links ul.super > li > a:before {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 0;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-left: 5px solid #9d9d9d;
  }
  footer .foot-right .f-links ul.super > li > a:hover:before {
    border-left: 5px solid #1469aa;
  }
  footer .foot-right .f-links ul.super > li span:before {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: -3px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top: 5px solid #9d9d9d;
  }
  footer .foot-right .f-links ul.super > li:last-of-type {
    width: 20%;
  }
  footer .foot-right .f-links ul.child li a {
    margin-left: 1em;
  }
  footer .foot-right .f-links ul.child li a small {
    display: block;
    vertical-align: middle;
    color: #9d9d9d;
    font-size: 12px;
  }
  footer .foot-right .f-links ul.child li a:before {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    left: -2px;
    width: 5px;
    height: 5px;
    border-top: 1px solid #9d9d9d;
    border-right: 1px solid #9d9d9d;
    transform: rotate(45deg);
  }
  footer .foot-right .f-links ul.child li a:hover:before {
    border-top: 1px solid #1469aa;
    border-right: 1px solid #1469aa;
  }
  #menu-btn {
    display: none;
  }
  #pagetop,
  #pageback {
    width: 80px;
    height: 80px;
  }
  #lower #main-view h2 strong {
    font-size: 26px;
  }
  #lower .sec {
    padding: 6% 0 0;
  }
  #lower .sec .sec-head {
    text-align: left;
    margin: 0 0 4%;
  }
  #lower .sec .sec-head h3 {
    font-size: 20px;
  }
  #breadcrumb {
    display: block;
    margin: 0 auto;
    box-sizing: content-box;
  }
  #breadcrumb .inner {
    padding: 15px;
  }
  #breadcrumb .inner ol {
    overflow: hidden;
  }
  #breadcrumb .inner ol li {
    float: left;
    font-size: 13px;
    margin: 0px 10px 0px 0px;
  }
  #breadcrumb .inner ol li a {
    background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 5px;
    padding: 0px 14px 0px 0px;
  }
  #breadcrumb .inner ol li a:hover {
    text-decoration: underline;
  }
  #breadcrumb .inner ol li.categorize {
    background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 8px;
    padding: 0px 14px 0px 0px;
  }
}

@media screen and (min-width: 1000px) {
  .h-draw span svg,
  .h-draw-white span svg {
    height: 35px;
  }
  nav#global {
    display: inline-block;
    line-height: 1.8;
  }
  nav#global ul.super > li {
    display: inline-block;
  }
  nav#global ul.super > li > a,
  nav#global ul.super > li > span {
    text-align: center;
    letter-spacing: .1em;
    display: block;
    padding: 15px 25px;
    font-size: 13px;
  }
  nav#global ul.super > li > a small,
  nav#global ul.super > li > span small {
    display: block;
    color: #ccc;
    font-size: 11px;
  }
  footer {
    margin: 100px 0 0;
  }
  footer .inner {
    padding: 100px 15px 50px;
  }
  #lower #main-view h2 strong {
    font-size: 30px;
  }
  #lower .sec .sec-head {
    margin: 0 0 40px;
  }
  #lower .sec .sec-head h3 {
    font-size: 22px;
  }
}

@media screen and (min-width: 1200px) {
  nav#global ul.super > li > a,
  nav#global ul.super > li > span {
    padding: 15px 30px;
    font-size: 14px;
  }
  #lower #main-view .main-bg span {
    overflow: hidden;
    height: 380px;
  }
  #lower #main-view .main-bg span img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
  }
  #lower #main-view h2 strong {
    font-size: 34px;
  }
  #lower .sec {
    padding: 70px 0 0;
  }
  #lower .sec h3 {
    font-size: 24px;
  }
}
